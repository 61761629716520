@import url(https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

body {
  background-color: #f1f5f8 !important;
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  line-height: 1.5715;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html,
body {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  display: none;
}

a {
  box-sizing: initial;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 10px;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
  display: block;
}

.no-internet img {
  width: 100%;
  height: 100vh;
}

.w-50 {
  width: 50% !important;
}

.w-70 {
  width: 70% !important;
}

.w-65 {
  width: 65% !important;
}

.w-30 {
  width: 30% !important;
}

.w-32 {
  width: 32.444%;
}

.w-49 {
  width: 49.22%;
}

.w-24 {
  width: 24%;
}

.w-50 {
  width: 49.5%;
}

.w-68 {
  width: 68% !important;
}

.w-20 {
  width: 20% !important;
}

.color-red {
  color: red;
}

.color-orange {
  color: #E46523 !important;
}

.border-orange {
  border-color: #E46523 !important;
}

.border-1-gray {
  border: 1px solid #ccc !important;
}

.border-none {
  border: none !important;
}

.border-blue {
  border-color: #033E8A !important;
}

.bg-white {
  background-color: white !important;
}

.color-gray {
  color: gray !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100%;
}

.w-66 {
  width: 66%;
}

.w-25 {
  width: 25% !important;
}

.w-80 {
  width: 80% !important;
}

.p-30 {
  padding: 30px !important;
}

.p-50 {
  padding: 50 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-30 {
  margin-top: 20px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.flex {
  display: flex;
}

.choose-country-select legend {
  display: none;
}

.action-buttons button.Mui-disabled {
  cursor: not-allowed !important;
  opacity: 0.7;
  pointer-events: all;
}

.MuiDataGrid-columnHeaderTitle {
  color: #000;
  font-weight: 700 !important;
}

.otp-errors {
  position: relative;
  text-align: center;
  margin: 0 auto;
  display: block;
  width: 100%;
}

.otp-errors .login-errors {
  position: absolute;
  margin: 0 0 0 0;
  bottom: -59px;
  width: 100%;
}

.email-verify-box.MuiBox-root:focus-visible {
  outline: 0 !important;
}

.customContainer {
  margin-top: 1.4rem !important;
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 440px);
  background: #fff !important;
  border-radius: 6px;
  padding: 1.5em;
  width: 82%;
  margin: 0 auto;
  position: relative;
}

.das-antd-checkbox {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

.info-tooltip-menu-onboarding {
  font-size: 1.3rem !important;
  cursor: pointer;
}

.toggle-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #efefef;
  padding: 0px 10px;
  border-radius: 50px;
  margin: 10px 0;
  border: 1px solid #ccc;
}

.toggle-div p {
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
}

.col-count-2 {
  column-count: 2;
}

.checkbox-wraps-box {
  position: relative;
  margin: -60px 0 0 0;
}

.all-onboarding-form {
  position: relative;
}

.all-onboarding-form::before {
  position: absolute;
  background: url(/static/media/bottom-sec-bgs.7e468d14.png) no-repeat;
  content: '';
  height: 300px;
  width: 100%;
  z-index: -1;
  top: -80px;
  background-size: cover;
}

.onboarding-form-wrap {
  position: relative;
}

.onboarding-form-wrap h2 {
  text-align: center;
  margin: 0 auto 20px;
  font-weight: 700;
  font-size: 1.4rem;
  text-transform: capitalize;
}

.onboarding-form-wrap p.onboarding-rules-form {
  text-align: center;
  margin: -17px 0 15px 0;
}

.customContainer .ant-col.ant-form-item-label label {
  font-weight: 600;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.customContainer .ant-col.ant-form-item-label label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
  position: absolute;
  font-size: 19px;
}

h1.forgot-password-title,
h1.reset-password-heading {
  margin-bottom: 70px !important;
}

span.astrick {
  color: red;
  position: relative;
  left: 2px;
}

.description-text {
  text-align: center;
}

.resend-button {
  color: #ff9800;
  font-weight: 700;
  margin: 8px 0 0 0;
  cursor: pointer;
  background: transparent;
  border: none;
}

.custom-action-bar {
  position: absolute;
  /* bottom: 0px; */
  /* z-index: 11; */
  display: flex;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
  width: calc(100% - 0vw);
  padding: 10px 0px 7px;
  background: #fff;
  /* left: 0px; */
  /* border-top: 1px solid #ccc; */
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  border-radius: 10px;
}

.cancel {
  background-color: gray !important;
}

form#register-form .phone-code-select .ant-form-item .ant-select-selector {
  border-radius: 17px 0px 0px 17px !important;
}

.phone-number-field .ant-input-number.ant-input-number-in-form-item {
  border-radius: 0px 40px 40px 0px;
  border-left: 0;
}

label.phone-number-label {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
}

.custom-action-bar button {
  padding: 8px 15px;
  border: 0;
  background: #ff9800;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  border-radius: 6px;
  position: relative;
  /* top: 5px; */
  cursor: pointer;
}

.new-login-box .MuiOutlinedInput-root.Mui-disabled,
.new-login-box .MuiOutlinedInput-root.Mui-disabled fieldset {
  pointer-events: none;
  cursor: not-allowed;
}

.choose-country-select label {
  position: relative;
  margin: 0 0 -20px 0 !important;
}

.choose-country-select label::after {
  position: absolute;
  left: auto;
  content: '*';
  color: red;
}

button.login-btn.disabled {
  background: lightgrey !important;
  cursor: not-allowed;
  pointer-events: none;
}

h4.last-registrarion-step-title {
  position: relative;
  margin: 0 0 10px 0;
  font-size: 1.2rem;
}

.new-login-wrapper.signup-wrapper .new-log-box .choose-country-code-select .MuiFormControl-root input {
  font-size: 14px;
  padding: 1px 0;
}

.all-onboarding-form .stakeholder-page .MuiDataGrid-virtualScroller.css-1w5m2wr-MuiDataGrid-virtualScroller {
  height: 40vh !important;
}

.add-stakeholder-button {
  position: relative;
  vertical-align: middle;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  padding: 5px 10px;
  background: #ffffff;
  width: 16px;
  border-radius: 40px;
  color: #ff9800;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff9800;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  cursor: pointer;
  float: right;
  border-radius: 8px;
  margin: 0px 0 5px;
}

.clearfix {
  clear: both;
}

.stakeholder-action-buttons {
  position: relative;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.stakeholder-action-buttons svg {
  position: relative;
  cursor: pointer;
  font-size: 1rem;
}

.w-100.ubo-checkbox {
  position: relative;
  margin: 0 0 -15px 0;
}

.payment-methods-form .w-100 {
  margin: 0 0 15px 0;
}

.payment-methods-form .payment-switch .ant-switch {
  height: 2px;
  line-height: 2px;
}

.payment-methods-form .payment-switch .ant-switch .ant-switch-handle {
  top: -8px;
  /* inset-inline-start: 0px; */
}

.pos-ralative img {
  width: 2rem;
}

.table-btn.list-btn.view-details.approveButton {
  color: #FF9800;
  background: #fff;
  top: -45px !important;
}

.table-btn.list-btn.view-details.approveButton:hover {
  background: #FF9800;
  color: #fff !important;
  border-color: #FF9800;
}

.table-btn.list-btn.view-details.approveButton:hover strong,
.table-btn.list-btn.view-details.approveButton:hover svg path {
  color: #fff !important;
}

.custom-next-prev .progress-wrap {
  position: relative;
  top: 6px;
}

.pos-ralative.hasPayScheme img.scheme-img {
  position: relative;
  top: 7px;
  width: 48px;
}

.pl-20.final-d-flex.neccessary-details {
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.final-d-flex {
  display: flex;
  grid-gap: 50px;
  grid-gap: 50px;
  gap: 50px;
}

.pl-20 {
  padding-left: 20px;
}

.neccessary-details .final-step-heading {
  width: 36%;
}

.final-step-heading {
  min-width: 22%;
  margin: 12px 0 0;
}

.final-step-heading h3 {
  color: #333;
  font-family: "Public Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.neccessary-image {
  width: 35%;
}

button.forgot-password.n-f-password {
  width: auto;
  float: right;
  margin: 20px 0 0px 0;
  position: relative;
}

.pop-up-wrapper h4 {
  text-transform: uppercase;
  letter-spacing: .03em;
  margin: 0px 0px 15px 0px;
  padding: 0 0 10px 0px;
  font-family: "Poppins", sans-serif;
  /* display: none; */
}

.popup-scroll-box {
  position: relative;
  height: calc(100vh - 215px);
  overflow: hidden;
  overflow-y: auto;
  padding: 5px 6px 0 0;
  flex-direction: column-reverse;
  display: flex;
  justify-content: flex-end;
}

/* Track */
.popup-scroll-box::-webkit-scrollbar-track,
.popup-scroll-box::-webkit-scrollbar-thumb {
  display: block;
}

.popup-lists {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 7px 15px;
  box-shadow: 0px 2px 4px 0px #ccc;
  position: relative;
  margin: 0 0 7px 0;
  margin: 0 0 7px 20px;
}

.pop-up-wrapper.case-history-wrapper .popup-lists::before {
  position: absolute;
  left: -19px;
  content: "-";
  top: 21px;
  color: #4f4f4f;
  transform: rotate(180deg);
}

.subs-id h5 {
  font-size: .8em !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif;
  margin: 0 0 0 0 !important;
}

.billing-date {
  position: relative;
  font-size: .65rem;
  color: #555;
  font-family: "Poppins", sans-serif;
}

.billing-cycle {
  font-size: .72rem;
  position: absolute;
  right: 12px;
  top: 6px;
  font-family: "Poppins", sans-serif;
}

.transaction-tab-body .MuiBox-root {
  padding: 24px 0;
}

.subscription-status h4 {
  margin: 0px -15px 5px -15px;
  padding: 0 0 4px 15px;
  color: #000;
  font-size: .75rem;
  font-weight: 600;
  letter-spacing: .03em;
  border-bottom: 1px solid #d3d3d3;
  font-family: "Poppins", sans-serif;
  display: block;
}

.subscription-status h4.subscription-successfull {
  color: #4CAF50;
}

.subscription-status h4.subscription-not-successful {
  color: #FF5722;
}

.subs-id button.ant-btn.token-id-button {
  border: 0;
  box-shadow: 0 0 0;
  padding: 0 0;
}

p.transaction-history-amonut,
p.transaction-history-status {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  position: absolute;
  right: 15px;
}

.popup-scroll-box p.transaction-history-amonut {
  top: 7px !important;
  font-size: .85em;
  color: #000;
}

.drawer-body.transaction-detail-drawer .bg-history-container ul.MuiTimeline-root button.MuiButtonBase-root {
  justify-content: left;
  padding: 0;
}

.token-popup-scroll-box {
  justify-content: start;
  flex-direction: column;
}

button.list-btn.list-refund-btn strong {
  color: #fcfcfc !important;
}

button.MuiButton-root.table-btn.list-btn.view-details.view-details-btn strong {
  color: #134fa6;
}

button.MuiButton-root.table-btn.list-btn.view-details.view-details-btn {
  border-color: #134fa6 !important;
}

h5.chargeback-title {
  font-size: 1em;
  color: #019ffa !important;
  text-transform: uppercase;
  margin: 12px 0 5px 0;
}

h5.chargeback-title:first-child {
  margin-top: 0;
}

.pop-up-wrapper.case-history-wrapper .popup-scroll-box {
  flex-direction: column;
  justify-content: flex-start;
}

.popup-scroll-box.subs-popupbox {
  justify-content: start;
  flex-direction: column;
}

.popup-scroll-box.subs-popupbox .popup-lists {
  margin-left: 0;
}

.pop-up-wrapper.case-history-wrapper .popup-lists {
  margin-left: 20px;
}

.pop-up-wrapper.case-history-wrapper .popup-lists::before {
  position: absolute;
  left: -19px;
  content: "-";
  top: 21px;
  color: #4f4f4f;
  transform: rotate(180deg);
}

.box-listing label {
  display: block;
}

.transaction-popper-data-wrap {
  position: relative;
  min-width: 50rem;
  display: block;
  max-width: 50rem;
  column-count: 2;
  padding: 10px 20px;
}

.popper-data-wrap {
  display: block;
  margin: 0 0 6px 0;
  border-bottom: 1px solid #ccc;
  height: 60px;
  position: relative;
}

.p-info-wrappers {
  position: relative;
  width: 60rem;
}

.m-info-wrappers {
  position: relative;
  width: 45rem;
  padding: 0 15px;
}

.a-info-wrappers {
  position: relative;
  /* width: 100%; */
  padding: 0 15px;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.a-info-wrappers .box-listing.acquier-box-listing {
  margin-top: 3px;
}

.a-info-wrappers h4.transaction-title.acquirerDetailsContainer {
  display: none;
}


.p-info-wrappers h3.drawerHeading {
  top: -6px;
  left: -3px;
  position: relative;
  margin: -15px -35px 12px -30px;
  border-bottom: 1px solid #ccc;
  padding: 0 0 10px 30px;
  color: #000;
}

.m-info-wrappers h4.transaction-title {
  border-bottom: 1px solid #ccc;
  padding: 0 0 10px 26px;
  margin: 6px -27px 10px -27px;
}

.a-info-wrappers h4.transaction-title.acquirerDetailsContainer {
  border-bottom: 1px solid #ccc;
  padding: 0 0 10px 26px !important;
  margin: 6px -27px 10px -27px !important;
}

.m-info-wrappers .box-listing {
  width: 48%;
}

.a-info-wrappers .box-listing.acquier-box-listing {
  width: 48%;
}

.a-info-wrappers .box-listing.acquier-box-listing:nth-child(12) {
  width: 100% !important;
}

.a-info-wrappers hr.MuiDivider-root {
  display: none;
}

.p-info-wrappers .product-wraps-product-drawer {
  height: auto;
}

.p-info-wrappers .product-drawer-details {
  height: auto;
}

.p-info-wrappers .product-wraps-inner-product-drawer {
  width: 33%;
}

.profile-button {
  display: inline-block;
  position: relative;
  margin: 0px 1em 10px 0;
  top: 4px;
}

.profile-button button {
  border: 1px solid #ff9800;
  background: transparent;
  padding: 4px 12px;
  height: auto;
  display: flex;
  border-radius: 8px !important;
  position: relative;
  margin: 0px 0 0px 0;
  cursor: pointer;
}

.profile-button button:hover {
  background: #ff9800;
  color: #fff;
}

.transaction-table {
  position: relative;
}

/*   New Transaction Download css*/
/* New transaction download approach*/
.MuiDialog-root.blue-theme.dateRangePicker_Popup.new-transaction-download-popup .MuiPaper-root.MuiPaper-rounded.MuiDialog-paper {
  max-width: 920px;
  width: 90%;
  height: auto;
  max-height: 100%;
}

.transaction-download-form button.add-new-filter {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.transaction-download-form {
  position: relative;
  margin: 0 auto;
  width: 70%;
  text-align: left;
}

.transaction-download-form .filter-footer button.reset-filter {
  /* display: none; */
}

.t-download-list-filter span.refresh.ant-tooltip-disabled-compatible-wrapper.tooltip-menu {
  display: block !important;
  right: 0 !important;
  left: auto !important;
  top: -42px !important;
  background: #fff !important;
  border-color: #ccc !important;
}

.t-download-list-filter span.refresh.ant-tooltip-disabled-compatible-wrapper.tooltip-menu .ant-btn[disabled] {
  background: transparent;
  border: 0;
  color: #ccc;
  min-width: 40px !important;
  padding: 5px 8px !important;
}

.transaction-download-form .filter-footer {
  margin: 0 0 15px 0;
}

.transaction-download-form .filter-footer button.Apply-filter {
  margin-right: 0;
}

.transaction-download-table {
  position: relative;
  text-align: left;
}

.downoad-request-button {
  position: relative;
  margin: 0px 0px 23px -30px;
  top: -10px;
  display: block;
  border-top: 1px solid #dedede;
  width: 106%;
  padding: 15px 0 15px 0;
}

img.request-download-icon {
  width: 24px;
  margin: 0 7px 0 0;
  vertical-align: middle;
  position: relative;
}

button.requestbtn {
  position: relative;
  padding: 8px 20px;
  border: 1px solid;
  background: transparent;
  color: #1890ff;
  border-radius: 6px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .04rem;
  cursor: pointer;
  margin: 7px 0 15px;
  display: block;
}

button.requestbtn[disabled] {
  opacity: 0.5;
  color: #000;
  cursor: not-allowed;
  background: #ccc;
}

button.requestbtn svg {
  position: relative;
  top: 0px;
  margin: 0 0 -7px 0;
}

.transaction-download-wrapper {
  position: relative;
  margin-top: -20px;
}

.transaction-download-form .filter-scroller {
  max-height: 25vh;
}

.transaction-download-form .filter-scroller::-webkit-scrollbar-track,
.transaction-download-form .filter-scroller::-webkit-scrollbar-thumb {
  display: block;
}

.transaction-download-form .filter-scroller button.remove-current-filter {
  right: 0;
  color: #ff5656;
}

span.filtered-items {
  position: relative;
  top: 7px;
  cursor: pointer;
  color: #FF9800;
}

div#transaction-filter-popover .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  padding: 20px 20px;
  min-width: 280px;
}

div#transaction-filter-popover .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded h3 {
  margin: -8px -21px 12px -21px;
  font-size: 1.1em;
  color: #000;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  padding: 0 15px 7px 17px;
  font-weight: 600;
}

div#transaction-filter-popover .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .filt-listing h4 {
  font-weight: 600;
}

div#transaction-filter-popover .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .filt-listing h4 span {
  font-weight: 400;
  color: #8d8d8d;
  display: inline-block;
  margin: 0 0 0 5px;
}

button.ant-btn.ant-btn-default.filtered-items {
  position: relative;
  top: 5px;
  min-width: auto;
  border: 0;
  background: transparent;
  color: #e56523;
}

button.close-filter-popper {
  position: absolute;
  right: 9px;
  top: 11px;
  background: transparent;
  border: 0;
  z-index: 99999;
  cursor: pointer;
}

.confirm-close {
  position: absolute;
  right: 20px;
  top: 17px;
}

.confirm-close button.btn-close {
  opacity: 1;
  filter: invert(1);
  cursor: pointer;
}

.ag-cell.Initiated {
  color: #faad14;
}

.ag-cell.Ready {
  color: green;
}

badge.app-filter-count {
  position: absolute;
  background: transparent;
  width: 17px;
  height: 17px;
  font-size: 10px;
  border: 1px solid #e56523;
  border-radius: 60px;
  padding: 0px 0;
  font-weight: 600;
  right: 4px;
  top: -3px;
  color: #e56523;
}

.transaction-download-table button.refresh {
  position: absolute !important;
  right: 0px !important;
  width: 40px;
  left: auto !important;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -35px !important;
}

.table-btn.view-details:disabled {
  opacity: .6;
  color: #c3c3c3 !important;
}

.blue-theme.dateRangePicker_Popup.new-transaction-download-popup .MuiDialog-container {
  align-items: flex-start;
}

img.pending-download {
  margin: 0 0 0 13px;
  width: 16px;
  text-align: center;
  position: relative;
  padding: 0 0px;
  cursor: not-allowed;
  background: transparent;
  opacity: .7;
}

.transaction-download-table h3 {
  font-size: 1.2em;
}

.blue-theme.dateRangePicker_Popup.new-transaction-download-popup h4 {
  font-size: 1.2em;
  margin: 5px 0 27px;
}

.ag-cell.Failed {
  color: #fe3030;
}

img.pending-download.failed-download {
  width: 18px;
  opacity: 1;
}

.downoad-request-button button.requestbtn:disabled {
  background: #ccc;
  color: #000;
}

.downoad-request-button button.requestbtn {
  border: 1px solid;
  padding: 5px 16px;
  border-radius: 6px;
  font-size: 13px;
  background: #f6921e;
  color: #fff;
}

.ag-theme-alpine.w-100.t-download-list-filter {
  position: relative;
  padding: 12px 0 10px 0;
}

.ag-theme-alpine.w-100.t-download-list-filter::before {
  position: absolute;
  left: -21px;
  right: 0px;
  top: 0;
  bottom: 0;
  content: "";
  width: 106%;
  height: 1px;
  background: #ccc;
}

.ag-theme-alpine.w-100.t-download-list-filter button.ant-btn-default.refresh {
  right: 0 !important;
  left: auto !important;
  display: block;
  top: 60px !important;
  z-index: 99;
}

.ag-theme-alpine.w-100.t-download-list-filter .das-table .MuiDataGrid-root {
  height: 44vh;
}

.filters-wrapper {
  position: relative;
}

.filters-wrapper span.ant-badge.app-filter-count {
  background: #f6921e;
  width: 18px;
  height: 18px;
  top: -19px;
  color: #fff;
  border-radius: 21px;
  font-size: 10px;
  padding: 4px 5px;
}

/* .ant-tooltip.ant-tooltip-hidden {
  z-index: 9999999 !important;
  display: block;
} */
button.download-csv-button.n-download-button {
  position: absolute;
  top: -46px;
  left: 1px;
}

.transaction-table .table-icons button.filter-btn {
  top: -46px;
}

.transaction-popper-drawer {
  padding: 15px 26px;
  position: relative;
}

.transaction-popper-drawer h4 {
  position: relative;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
}

.transaction-popper-drawer .t-download-list-filter h3 {
  position: relative;
  font-size: 1.2em;
  font-weight: 500;
  margin: 0 0 12px;
}

button.download-limit-ok {
  display: block;
  margin: 20px auto 0;
  background: #FF9800;
  border: 0;
  padding: 6px 22px;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
}

.MuiDialog-root.show-max-limit-reached-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  position: absolute;
  right: 7%;
}

.MuiDialog-root.show-max-limit-reached-popup .action-buttons {
  display: none;
}

.onboarding-snack-bar {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
  background: linear-gradient(14deg, #F4AE40, #ce6224);
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 30px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: white;
}

.onboarding-snack-bar svg {
  position: relative;
  top: 1px;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
}

.w-100.w-min-label label {
  width: min-content !important;
}

form#register-form .w-100.ownership-checkbox .ant-input-number-group-addon {
  border-radius: 0px 20px 20px 0px !important;
}

form#register-form .w-100.ownership-checkbox .ant-input-number-affix-wrapper.ant-input-number-outlined.ant-input-number-has-feedback {
  border-radius: 40px 0px 0px 40px !important;
}

.w-100.upload-form-label label {
  width: min-content !important;
  pointer-events: none !important;
  cursor: no-drop !important;
}

.drawer-body.transaction-detail-drawer .bg-history-container .transaction-tab-body .MuiBox-root {
  height: calc(100vh - 220px);
  overflow: hidden;
  overflow-y: auto;
}

.ag-theme-alpine.w-100.t-download-list-filter .footer .footer-left {
  display: none !important;
}

p.confirm_mid_checkbox_heading {
  text-transform: uppercase;
  font-size: .9rem;
  font-weight: 600;
}

p.confirm_mid_checkbox_heading.form-new-heading {
  display: block;
  margin: 25px 0 0px 0;
}

.risk-statement.create-subscription-drawer.susbcription-drawer-form .MuiGrid-item.MuiGrid-grid-xs-12 {
  width: 98% !important;
}

.risk-statement.create-subscription-drawer.susbcription-drawer-form .MuiGrid-item.MuiGrid-grid-xs-6 {
  width: 48% !important;
}

.MuiDrawer-root.MuiDrawer-modal.drawer-wrapper.view-subscriber-drawer {
  z-index: 99999;
}

/* React-Tour */

.react-tour-button {
  color: white;
  padding: 10px;
  background-color: #f6921e;
  border: 1px solid #f6921e;
  border-radius: 5px;
  cursor: pointer;
}

.reactour__helper h3 {
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-weight: bold;
  font-family: "Poppins", sans-serif !important;
  color: #f6921e;
}

.pro-detail-table {
  position: relative;
}

.new-data-table {
  position: relative;
}

.new-data-table button.filter-btn {
  top: -46px;
}

/*  Registration Completed  */
.registration-completedd-wrapper {
  background: #fff;
  padding: 30px 20px;
  height: auto;
  border-radius: 6px;
}

.registration-completedd-wrapper p.header-title {
  font-size: 27px;
  color: #231F20;
  font-family: 'Public Sans', sans-serif;
  font-weight: bolder;
  letter-spacing: 0.12px;
}

.registration-completedd-wrapper .label-text {
  margin: 0 0 17px 0;
  font-size: 15px;
  font-weight: 500;
  color: grey;
}

.registration-completedd-wrapper .label-text a {
  color: #ed6c01;
  text-decoration: none;
}

.registration-completedd-wrapper button.ant-btn.ant-btn-default.try-button {
  height: 52px;
  border: none;
  color: #fff;
  border-radius: 50px !important;
  cursor: pointer;
  background: var(--Linear, linear-gradient(92deg, #F6931E 0%, #E56523 100%)) !important;
  padding: 12px 30px !important;
  font-weight: 500;
}

.label-text-upp.label-text {
  text-transform: uppercase;
  font-weight: bold;
}

.label-text-upp.label-text {
  text-transform: uppercase;
  margin: 23px 0 10px 0;
  font-weight: bold;
  font-size: 17px;
}

h5.total-records-found {
  position: absolute;
  margin: 0 auto;
  text-align: center;
  font-size: .95em;
  font-weight: 500;
}

.fileUploadErrorMsg {
  font-size: 12px !important;
  color: #ff4d4f !important;
  font-weight: 500 !important;
}

.downloadDoc .ant-upload.ant-upload-select-picture-card {
  pointer-events: none;
}

.disbaleUploadOption .ant-upload.ant-upload-select {
  pointer-events: none !important;
  opacity: 0.7 !important;
}

.downloadDoc .ant-tooltip-inner {
  display: none !important;
}

.downloadDoc .ant-tooltip-arrow {
  display: none !important
}

.downloadDoc .ant-upload-list-item.ant-upload-list-item-error {
  border-color: #d9d9d9 !important;
}

.downloadDoc span.ant-upload-list-item-name {
  color: rgba(0, 0, 0, 0.85) !important;
}

.downloadDoc .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #e6f7ff !important;
}

.downloadDoc svg path:nth-child(2) {
  fill: #1890ff !important;
}

.pro-detail-table.subs-pro-detail .subscriptionButtons button.download-csv-button {
  top: -45px;
  right: 75px;
}

.MuiDialog-root.red-theme.MuiModal-root {
  z-index: 9999;
  left: 0;
  top: 25px;
}

.pro-detail-table.sales-lead-table-page {
  margin: 40px 0 0 0;
}

.tabs-layout .pro-detail-table.sales-lead-table-page {
  margin: 0;
}

.pro-detail-table.holiday-table-view {
  margin: 8px 0 0 0;
}

.pro-detail-table.holiday-table-view .new-data-table button.filter-btn {
  top: -53px;
}

.pro-detail-table.holiday-table-view button.addHoliday {
  top: -51px !important;
  position: absolute;
  right: 72px;
}

.table-btn.h-list-btn {
  padding: 6px 5px !important;
  position: relative;
  top: 0px;
  border: 0;
}

.table-btn.h-list-btn:hover {
  border: 0;
}

.MuiAutocomplete-popper {
  z-index: 99999 !important;
}

/* ============= sales lead buttons ==============*/
.sales-btns-wrap {
  position: absolute;
  top: 8px;
  padding: 0px 0px 0;
  margin: 0px 0px 0 0px;
  right: 0;
  width: 100%;
}

.salesButtonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: right;
  grid-gap: 9px 0px;
  gap: 9px 0px;
}

.sales-btns-wrap button.btn {
  margin: 0 7px 0 0;
  font-family: "Poppins", sans-serif;
  min-width: 170px;
  padding: 8px 25px;
  border-radius: 40px !important;
}

.sales-btns-wrap button.btn:last-child {
  margin: 0;
}

.sales-btns-wrap button.btn svg {
  margin: 0 7px 0 0;
  width: 1rem;
  height: 1rem;
}

.sales-onb-detail-left {
  position: relative;
  margin-bottom: 10px;
  margin-top: -11px;
}

.sales-onb-detail-left h3 {
  font-size: 21px;
  color: #496c8e;
  font-family: "Public Sans", sans-serif;
  margin-bottom: -8px;
  font-weight: 400;
}

.sales-onb-detail-left h5 {
  font-size: 1.1em;
  margin: 6px 0 2px;
  color: #496c8e;
  font-weight: 400;
}

.sales-onb-detail-left span.app-status {
  text-transform: uppercase;
  color: #00baf3;
  font-weight: bold;
}

.sales-onb-detail-left h6 {
  font-size: .8rem;
  color: #000000;
  margin-top: 0;
  font-weight: 400;
}

.sales-onb-detail-left h6 span.app-status {
  color: #000000;
  font-weight: bold;
}

.auto-complete-comp .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
  display: none;
}

ul#controlled-demo-dasmid-listbox .MuiAutocomplete-option[aria-selected="true"] {
  background-color: #FF9800 !important;
  color: #fff !important;
  border: 1px solid white !important;
  position: relative;
}

ul#controlled-demo-dasmid-listbox .MuiAutocomplete-option[aria-selected="true"]::after {
  content: "✓";
  height: 2px !important;
  width: 2px !important;
  color: #fff !important;
  top: 5px;
  right: 2rem;
  position: absolute;
}

ul#controlled-demo-dasmid-listbox .auto-complete-comp .MuiOutlinedInput-root .MuiAutocomplete-input {
  height: 4px !important;
}

ul#controlled-demo-dasmid-listbox .auto-complete-comp input::placeholder {
  color: black !important;
}

input#controlled-demo-dasmid {
  height: 6px !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #f86f19 !important;
  border-width: 2px;
}

.refCodeEmailDescription {
  padding-bottom: 28px;
  color: rgba(0, 0, 0, 0.26);
}

.box-listing.salesLeadCopyIconContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  /* width: 18%; */
}

.box-listing.salesLeadCopyIconContainer+.box-listing.salesLeadCopyIconContainer {
  width: 48%;
  display: inline-flex;
}

.risk-statement.support-form-content.missing-info-drawer-form {
  padding: 35px 15px 0;
}

ul.MuiAutocomplete-listbox li {
  font-size: .78rem;
  width: 100%;
  /* overflow: hidden !important;
  text-overflow: ellipsis;
  display: block !important;
  white-space: nowrap; */
}

.drawer-header {
  background: #484848;
  height: 48px !important;
  min-height: 48px !important;
}

.drawer-header svg {
  color: #fff !important;
}

.drawer-header span h3 {
  color: #fff !important;
}

.MuiPaper-root.MuiDrawer-paper button.das-add-btn.setup-risk-btn {
  color: #fff !important;
  border-color: #fff !important;
}

.Initiated {
  color: #faad14;
}

.Ready {
  color: green;
}

.Failed {
  color: #fe3030;
}

.filter-scroller::-webkit-scrollbar-thumb,
.filter-scroller::-webkit-scrollbar-track {
  display: block !important;
}

.transaction-popper-drawer .t-download-list-filter h3 svg {
  position: relative;
  font-size: 20px;
  left: 1%;
  top: 4px;
  color: #FF9800;
}

ul#controlled-demo-dasmid-listbox::-webkit-scrollbar-thumb,
ul#controlled-demo-dasmid-listbox::-webkit-scrollbar-track {
  display: block !important;
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper::-webkit-scrollbar-thumb,
.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper::-webkit-scrollbar-track {
  display: block !important;
}

.add-text-title {
  color: #fff;
  font-weight: 700;
}

.blacklisted-prohibited-lists button {
  min-width: max-content;
  height: 29px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button.das-add-btn.setup-risk-btn.m-risk-btn {
  top: -42px !important
}

.APPROVAL.IN.PROGRESS div {
  color: #ffc015;
  font-weight: 600;
}

#menu- .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  min-width: 20rem;
}

li.MuiButtonBase-root.menu-ui-list {
  width: 320px !important;
  overflow: hidden;
  white-space: normal;
}

span.tooltip-icons svg {
  font-size: 1rem;
  position: relative;
  left: 5px;
  top: 2px;
}

.acquirer-detail-table .aquirer-mid-table .footer-left {
  /* display: none; */
}

.custom-p-numb label.phone-number-label {
  margin: 0 0 8px 0;
  display: inline-block;
}

.what-type-account {
  position: relative;
  margin: 0px 0 -40px 0;
}

.drawer-body.pricing-drawer-body.edit-status-drawer form.detail-form.refund-form .MuiGrid-root:last-child {
  margin-top: 12px;
}

.drawer-body.pricing-drawer-body.edit-status-drawer form.detail-form.refund-form .MuiGrid-root:last-child p.MuiFormHelperText-root {
  display: block !important;
}

button.Mui-disabled svg path {
  color: #ccc;
}

button.table-btn.view-settings:disabled,
button.table-btn.pbl-copy-btn:disabled {
  opacity: 0.7;
}

button.table-btn.view-settings:disabled svg path,
button.table-btn.pbl-copy-btn:disabled svg path {
  color: #ccc !important;
}

.confirm-popup-body h4:nth-child(2) {
  margin: -25px 0 15px 0;
}

.google-mid {
  margin-top: 15px !important;
}

.custom-next-prev button.MuiButtonBase-root {
  min-width: 90px !important;
}

.extraDocMsgs div p {
  position: relative;
  display: block;
  width: 100%;
  word-break: break-word;
  margin: 0 0 10px 0;
}

.extraUploadedDocInfo label {
  display: block;
  word-break: break-word;
  padding-right: 109px;
  margin: 0 0 5px 0;
}

.m-10.uploadDocContainer textarea {
  padding: 20px 20px 20px 20px !important;
  resize: none;
}

span.text-helper-text {
  margin: 9px 0 0 0;
  display: block;
  font-size: .8rem;
  color: #797979;
}

.inner-details .box-listing h4 {
  word-wrap: break-word;
}

.drawer-body.transaction-detail-drawer .detail-form.info-container.transaction-detail-container .chargeback-status-btns button {
  color: #fff;
}

button.MuiButtonBase-root.table-btn.list-btn.view-details.chargeback-detail-view strong {
  color: #0c4fa6;
}

button.MuiButtonBase-root.table-btn.list-btn.view-details.chargeback-detail-view {
  border-color: #0c4fa6;
}

.merchant-risk-drawer .prohibited-output-wrap {
  width: 90%
}

.merchant-risk-drawer .blacklisted-prohibited-lists .prohibited-list-upload-error {
  margin-top: 10px !important;
  top: 0;
}

.merchant-risk-drawer .blacklisted-prohibited-lists {
  margin: 20px 0 0 17px;
  width: 30% !important;
}

.merchant-risk-drawer .blacklisted-prohibited-lists label {
  top: 7px;
  left: -3px;
}

.merchant-risk-drawer .blacklisted-prohibited-lists .file-upload-container label.mt-20 {
  left: -3px;
  top: 20px;
}

.merchant-risk-drawer .blacklisted-prohibited-lists .file-upload-container label {
  top: 17px;
}

.In.Progress {
  color: #06b1ef;
}

.flex-row.align-items-center.product-risk-drawer-wp {
  grid-gap: 12px;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;
}

.flex-row.align-items-center.product-risk-drawer-wp span.ant-input-group-addon {
  border: 0;
}

form#register-form .flex-row.align-items-center.product-risk-drawer-wp span.ant-input-group-addon .ant-select.ant-select-single .ant-select-selector {
  border-left: 0 !important;
  border-radius: 0px 40px 40px 0px !important;
}

form#register-form .flex-row.align-items-center.product-risk-drawer-wp span.ant-input-group-addon .ant-select.ant-select-single {
  border-left: 0 !important;
  border-radius: 0px 0px 0px 0px !important;
}

form#register-form .flex-row.align-items-center.mt-20.product-risk-drawer-wp .ant-row .ant-form-item-control-input-content span.ant-input-affix-wrapper {
  border-radius: 40px 0 0 40px !important;
}

.flex-row.align-items-center.product-risk-drawer-wp .w-32 {
  width: 100%;
}

.flex-row.align-items-center.mt-20.prohib-count-wrap .\31 0.flex-row.align-items-center.checkbox-with-label-risk {
  position: absolute;
  right: 0;
  top: -12px;
}

.flex-row.align-items-center.mt-20.prohib-count-wrap {
  position: relative;
}

.v-threshold span.ant-input-group-addon {
  border: 1px solid #ccc !important;
  border-left: 0 !important;
  border-radius: 0px 40px 40px 0px !important;
  background: #ffe9c8 !important;
}

.v-timeframes span.ant-input-group-addon .ant-select.ant-select-outlined {
  min-width: 140px;
}

.risk-wrapper.merchant-risk-wrap .heading {
  top: 13px;
}

.blacklisted-prohibited-lists.submit-upload-component {
  width: 100%;
  position: relative;
  margin: 0 0 20px 0;
}

.blacklisted-prohibited-lists.submit-upload-component input#FilePath {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 0 0;
  top: 0px;
  height: 50px;
}

.blacklisted-prohibited-lists.submit-upload-component button {
  position: relative !important;
  top: 10px;
  left: 16px;
  z-index: 0;
  padding: 0 6px;
}

.blacklisted-prohibited-lists.submit-upload-component {
  border: 1px solid #ccc;
  width: 97%;
  margin: 10px 0 10px 15px;
  border-radius: 50px;
  padding: 0 0 !important;
  height: 50px;
}

label.s-upl-label {
  position: absolute !important;
  left: 3px;
  padding: 0 5px !important;
  top: 2px !important;
}

label.no-choosen-file {
  position: relative !important;
  display: inline-block !important;
  top: 23px !important;
  left: 12px !important;
}

.MuiDialog-root.blue-popup.download-pdf-popup button.language-button p {
  display: none;
}

.deactivate-link.generate-link-btn {
  background: #969ca3 !important;
  margin-left: 10px !important;
}

.toggle-button {
  display: flex;
}

.toggle-button p {
  margin: 9px 5px 0;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  width: max-content;
}

/* .issueChargebackTable .footer-left {
  display: none;
} */

.merchant-details-drawer {
  display: flex;
  padding: 0px 22px;
  flex-direction: row;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.merchant-details-drawer h4.transaction-title {
  display: none;
  margin-top: 15px;
}

.merchant-details-drawer .box-listing {
  padding: 0px 0px;
  width: 50%;
  margin: 0 0 15px 0 !important;
}

.tab-buttons-transaction-details {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.tab-buttons-transaction-details .right-list {
  padding: 5px 5px 5px 5px;
  cursor: pointer;
  position: relative;
}

.tab-buttons-transaction-details .right-list span.icon-img-list svg {
  color: #938d8d;
}

.tab-buttons-transaction-details .right-list.active span.icon-img-list svg {
  color: #f87b22;
}

.tab-buttons-transaction-details .right-list.active::after {
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
  background: #FF9800;
  height: 3px;
  content: "";
}

h4.h-time-heading {
  position: relative;
  margin: 15px 0 15px 0;
  color: #e77236;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.03em;
}

.drawer-body.transaction-detail-drawer .bg-history-container h5:first-child {
  margin-top: -10px;
}

.drawer-body.transaction-detail-drawer .bg-history-container h5:nth-child(n+2) {
  margin-top: 10px;
}

.order-listing {
  display: flex;
  position: relative;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order-l-left {
  width: 70%;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

img.pro-img {
  width: 54px;
}

.pro-order-h h5 {
  position: relative;
  margin: 0 0 6px 0px !important;
  font-size: .85rem;
}

.pro-order-h h6 span,
.pro-order-h h6 {
  font-size: .8rem;
}

.order-total-ov {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px;
  margin: 10px 0 0 0;
}

.order-total-ov h5 {
  margin: 0 !important;
}

.MuiTypography-root.MuiTypography-body1.MuiTimelineContent-root.MuiTimelineContent-positionRight.transaction-history-content.is-selected {
  border: 1px solid orange;
  box-shadow: 0 25px 50px -12px rgba(253, 80, 0, 0.25);
}

.scrollable-section ul {
  overflow: hidden;
  overflow-y: auto;
  height: 70vh;
}

.timeline-section.dispute-timeline {
  max-height: 26vh !important;
}

.timeline-section.timeline-dispute-height.dispute-timeline {
  max-height: 38vh !important;
}

.v-timeframes span.ant-select-selection-item {
  color: #FF9800;
}

form#register-form .number-input-field .ant-input-number-affix-wrapper.ant-input-number-outlined.ant-input-number-has-feedback {
  border-radius: 40px 0px 0px 40px !important;
}

form#register-form .number-input-field .ant-input-number-group-addon {
  border-radius: 0px 40px 40px 0px !important;
  min-width: 140px;
}

form#register-form .number-input-field .ant-input-number-group-addon .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow {
  border-radius: 0px !important;
  border-width: 0 !important;
  width: 100%;
}

form#register-form .number-input-field .ant-input-number-group-addon .ant-select-selector {
  border: 0 !important;
  box-shadow: 0 0 0 !important;
}

.issueChargebackTable .das-table .MuiDataGrid-root.MuiDataGrid-autoHeight {
  height: 67vh;
}

.issueChargebackTable .das-table {
  margin-bottom: 2em;
}

.issueChargebackTable .das-table .MuiDataGrid-virtualScroller {
  height: 55vh !important;
}

button.mdrRatesActionBtn {
  border: 1px solid #FF9800;
  margin: 0 5px;
  color: #ff9800;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.onboarding-form-wrap .flex.w-100 button.mdrRatesActionBtn {
  position: absolute;
  right: 2px;
  top: 5px;
  z-index: 1;
}

/*    top header    */
.top-header-bar {
  background: #f6921e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}

.top-header-bar h6 {
  color: #fff;
  font-size: .8rem;
  font-weight: 500;
}

.top-header-bar h6 a {
  color: #fff;
  font-weight: 800;
  padding: 0 2px;
}

button.feedback-btn {
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 999999;
  background: #f6921e;
  border: 0;
  border-radius: 0px 10px 0px 10px;
  padding: 9px 6px 3px 7px;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

button.feedback-btn svg {
  color: #fff;
}

.feedback-form-wrap {
  position: absolute;
  right: 13px;
  z-index: 99999;
  bottom: 140px;
  min-height: 150px;
  background: #fff;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 30px 30px;
  min-width: 16em;
  max-width: 23em;
  border-radius: 15px 15px 15px 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border: 1px solid #f6f6f6;
}

.feedback-form-wrap form {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
}

.feedback-form-wrap form .form-control.feedback-input {
  padding: 10px 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.feedback-textarea {
  resize: none;
}

button.form-control.feedback-button {
  background: #f6921e;
  color: #fff;
  border: 0;
  padding: 11px 10px;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .06rem;
  cursor: pointer;
}

button.feedback-btn-close {
  position: absolute;
  right: 12px;
  top: 12px;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.feedback-form-wrap h4 {
  margin: -15px 0 5px 0;
  text-transform: uppercase;
  letter-spacing: .04rem;
}

.feedback-form-wrap form .ant-form-item:last-child {
  margin-bottom: 0;
}

.feedback-form-wrap button.ant-btn {
  background: #f6921e;
  border: 0;
  width: 100%;
}

.feedback-form-wrap button.ant-btn:hover {
  background: #d1750b !important;
}

.top-header-bar h6 button {
  border: 0;
  background: transparent;
  padding: 0;
  color: #fff;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.feedback-form-wrap .ant-form-item-explain-error {
  font-size: .8rem;
}

button.notification-popper {
  position: absolute;
  right: 640px;
  top: 15px;
  z-index: 112;
  background: transparent;
  border: 0;
  cursor: pointer;
  animation: horizontal-shaking 0.35s infinite;
}

button.notification-popper::after {
  position: absolute;
  width: 8px;
  height: 8px;
  content: "";
  background-color: #fe8f11;
  right: 0;
  top: 0;
  border-radius: 100px;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0)
  }

  25% {
    transform: translateX(2px)
  }

  50% {
    transform: translateX(-2px)
  }

  75% {
    transform: translateX(2px)
  }

  100% {
    transform: translateX(0)
  }
}

.notify-popper-box.MuiBox-root {
  background: #f6921e;
  border: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 6px;
  padding: 6px 20px;
}

.notify-popper-box.MuiBox-root h6 {
  color: #fff;
  font-size: .8rem;
  font-weight: 500;
}

.notify-popper-box.MuiBox-root h6 a {
  color: #fff;
  font-weight: 800;
  padding: 0 2px;
}

.notify-popper-box.MuiBox-root h6 button {
  border: 0;
  background: transparent;
  padding: 0;
  color: #fff;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.notify-popper {
  top: 8px !important;
}

button.notification-popper.not-auth-notification {
  right: 15px;
  top: 35px;
}

button.lang-button.active {
  background: #f6921e;
  padding: 5px 20px;
  border-radius: 4px;
  font-weight: 600;
  color: white;
  border: 2px solid rgb(172, 85, 15);
}

button.lang-button {
  background: #969ca3;
  padding: 5px 20px;
  border-radius: 4px;
  font-weight: 500;
  color: white;
  width: 8rem;
  height: 2.5rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  cursor: pointer;
}

.lang-change-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb,
.MuiAutocomplete-listbox::-webkit-scrollbar-track {
  display: block !important;
}

.blacklisted-prohibited-lists.submit-upload-component .prohibited-list-upload-error {
  position: absolute;
  bottom: -30px;
  top: auto;
  left: 6px;
}

.react-datepicker-wrapper {
  display: block !important;
}

/* ======= notification popper =========*/
.notification-popover-wrap {
  display: inline-block;
}

.notification-child-wrap {
  position: relative;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  max-height: 300px;
}

.notification-child-wrap::-webkit-scrollbar-thumb,
.notification-child-wrap::-webkit-scrollbar-track {
  display: block;
}

button.ant-btn.notification-popover {
  border: 0;
  position: relative;
  top: 7px;
  box-shadow: 0 0 0;
  max-width: 52px;
}

button.ant-btn.notification-popover span.ant-badge {
  top: -10px;
  background: #e56524;
  width: 20px;
  position: relative;
  height: 20px;
  border-radius: 50px;
  padding: 0 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  right: 23px;
  font-size: .73rem;
  font-weight: 600;
  color: #fff;
}

button.ant-btn.notification-popover:hover,
button.ant-btn.notification-popover:hover svg {
  color: #e56524 !important;
}

div#notify-popper .ant-popover-title {
  position: relative;
  border-bottom: 1px solid #ccc;
  padding: 0 12px 8px 12px;
  margin: 0px -12px 10px -12px;
}

div#notify-popper {
  max-width: 320px;
}

.notification-child-wrap p {
  position: relative;
  margin: 0 0 10px 0;
  font-size: .75rem;
}

.notification-child-wrap p:last-child {
  margin-bottom: 0 !important;
}

/* ====== change password ========*/
.change-password-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0;
  height: calc(100vh - 112px);
}

.change-password-page img.password-icon {
  width: 110px;
  position: relative;
  margin: 0 0 0 0;
  display: block;
}

.change-password-page .first-login-reset-page {
  margin: 25px auto;
}

.change-password-page h3 {
  text-transform: uppercase;
  position: relative;
  top: 8px;
}

.new-data-table .filters-wrapper span.ant-badge.app-filter-count {
  background: #f6921e;
  width: 18px;
  height: 18px;
  top: -3px;
  color: #fff;
  right: -12px;
  border-radius: 21px;
  font-size: 10px;
  padding: 4px 5px;
  position: absolute;
}

.new-data-table button.ant-btn.ant-btn-default.filtered-items {
  padding: 0px 5px;
  top: 4px;
}

.risk-statement.issue-chargeback-form .MuiFormControl-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd,
.drawer-body.add-entity-risk-rule.edit-merchant-statement.add-holiday-wrapper .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.FormFieldGrid-grid:nth-child(3) .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.risk-statement.issue-chargeback-form .MuiFormControl-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth.MuiTextField-root button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium,
.drawer-body.add-entity-risk-rule.edit-merchant-statement.add-holiday-wrapper .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.FormFieldGrid-grid:nth-child(3) .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
  -webkit-user-select: all;
  user-select: all;
  pointer-events: all;
}

.risk-statement.issue-chargeback-form .MuiAutocomplete-root.MuiAutocomplete-fullWidth.MuiAutocomplete-hasPopupIcon.chargeback-fields .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  -webkit-user-select: all;
  user-select: all;
  pointer-events: all;
}


/* ====== subscription-date field issue ======*/
.risk-statement.create-subscription-drawer.susbcription-drawer-form .MuiAutocomplete-root.Mui-focused.MuiAutocomplete-fullWidth.MuiAutocomplete-hasPopupIcon.create-subscription-fields .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  -webkit-user-select: all !important;
  user-select: all !important;
  pointer-events: all !important;
}

.risk-statement.create-subscription-drawer.susbcription-drawer-form .MuiFormControl-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.risk-statement.create-subscription-drawer.susbcription-drawer-form .MuiFormControl-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
  -webkit-user-select: all;
  user-select: all;
  pointer-events: all;
}

button.change-password-link {
  background: transparent;
  border: 0;
  color: #fa9006;
  cursor: pointer;
  font-weight: 600;
}

/* .menu-buttons .ant-space {
      width: 35px;
  }
    .menu-buttons .ant-space-item {
      width: 60px;
  } */

button.ant-btn.notification-popover span.badge {
  display: inline-block !important;
  right: 0 !important;
  position: absolute;
  left: auto;
}

/* .filter-body{
  position: relative;
  z-index: 1; 
} */
/* .filter-body:nth-child(2), .filter-body:nth-child(3), .filter-body:nth-child(4), .filter-body:nth-child(5) {
  position: relative;
  z-index: 2;
} */
.MuiStack-root.prompt-message.legacy-prompt-message .MuiSnackbarContent-message {
  padding-right: 15px;
}

.legacy-prompt-message .MuiSnackbarContent-action {
  position: absolute;
  right: 25px;
  z-index: 11;
}

.legacy-prompt-message .MuiSnackbarContent-action button.prompt-close {
  min-width: 32px;
}

.react-datepicker.border-1-gray.p-10.w-31 .react-datepicker__month-container {
  width: 50%;
}

.react-datepicker__close-icon::after {
  background-color: #e77236 !important;
}

.react-datepicker__day--selected {
  background-color: #e77236 !important;
}

.react-datepicker__day--in-range {
  background-color: #e77236 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #ffb38d !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #f79868c1 !important;
}

button.orange-ok-button:hover {
  color: #e77236 !important;
  border-color: #e77236 !important;
}

.das-table.is-legacy-prompt-message {
  height: calc(100vh - 260px) !important;
}

.popover-box.statement-action-option.MuiBox-root button.table-btn.list-btn.view-details {
  width: 11rem !important;
}

.statement-transactins-wrapper .transaction-table .das-table {
  height: calc(100vh - 340px);
}

.statement-top-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 97.5%;
  flex-wrap: wrap;
  grid-gap: 100px;
  gap: 100px;
  background: #fff;
  padding: 25px 20px 10px;
  margin: 0 0 60px 0;
  border-radius: 6px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.statement-wrap-left {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  margin: 0 0 15px 0;
}

.statement-wrap-left h3 strong {
  display: block;
  min-width: 225px;
  font-size: 1rem;
}

.statement-wrap-left h3 span {
  font-weight: 400;
  margin: 0 0 0 0px;
  font-size: .9rem;
}

.statement-wrap-left h3 {
  position: relative;
}

.statement-transactins-wrapper .transaction-table .das-table button.ant-btn {
  border: 0;
  position: relative;
  top: 2px
}

.scrollable-section ul::-webkit-scrollbar-thumb,
.scrollable-section ul::-webkit-scrollbar-track {
  display: block !important;
}

.scrollable-section ul::-webkit-scrollbar-thumb,
.scrollable-section ul::-webkit-scrollbar-track {
  display: block !important;
}

.detail-container::-webkit-scrollbar-thumb,
.detail-container::-webkit-scrollbar-track {
  display: block !important;
}

#onfido-mount iframe {
  display: block;
  height: 100% !important;
  width: 100% !important;
}

.onfido-wrapper.red-theme .confirm-popup-body::-webkit-scrollbar-thumb,
.onfido-wrapper.red-theme .confirm-popup-body::-webkit-scrollbar-track {
  display: block !important;
}

.onfido-wrapper.red-theme .MuiPaper-root.MuiPaper-elevation {
  max-height: 94vh;
  width: 26rem !important;
}

.MuiDialog-root.red-theme.onfido-wrapper.MuiModal-root {
  top: 0;
}

div#onfido-mount {
  position: relative;
  top: 20px;
}

.MuiDialog-root.red-theme.onfido-wrapper.MuiModal-root h2.confirm-heading {
  border-radius: 0 !important;
}

div#onfido-sdk {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  max-height: 100%;
}

.box {
  position: relative;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 99999;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  color: #fff;
  font: 300 12px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: -59px;
  right: -67px;
  z-index: 0;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.ribbon-top-right span svg {
  transform: rotate(-45deg);
}

.ribbon-top-right span svg {
  transform: rotate(-45deg);
  font-size: 1.5rem;
  position: relative;
  top: 5px;
}



.kyc-table .das-table {
  padding: 0px 5px 0px 10px;
  height: 35vh;
}

.kyc-table .das-table .MuiDataGrid-virtualScroller {
  height: 30vh !important;
}

.onfido-wrapper.red-theme .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .confirm-popup-body h4 {
  margin: 0 0 -36px 0;
}

.filter-ui-info-message {
  position: absolute;
  right: 90px;
  top: 67px;
  color: #fff;
  background: #FF9800;
  padding: 5px 9px 5px 9px;
  font-size: .75rem;
  border-radius: 5px;
}

.filter-ui-info-message p {
  margin-bottom: 0;
  display: flex;
  grid-gap: 2px;
  gap: 2px;
  justify-content: center;
  align-items: center;
}

.filter-ui-info-message p span {
  display: flex;
}

.filter-ui-info-message p span svg.MuiSvgIcon-root {
  font-size: 1.2rem;
  display: flex;
  position: relative;
  top: -1px;
}

.statement-transactins-wrapper .transaction-table .das-table .MuiDataGrid-virtualScroller {
  height: 44vh !important;
}

.statement-transactins-wrapper .transaction-table .tool-bars button.MuiButtonBase-root.table-btn.list-btn.view-details.approveButton {
  text-align: center;
  right: 10px;
  width: 7.2rem !important;
}

.action-button-popover .popover-box {
  width: 11rem;
}

/*
  MFA CSS
*/
.mfa-setup {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: transparent;
  position: relative;
  width: 100%;
  left: -5%;

}

.generatedQr {
  position: relative;
  display: flex;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  left: -60px;
}

.mfa-setup.mfa-toggled-setup {
  height: 72vh;
  overflow: hidden;
  overflow-y: auto;
}

.mfa-setup h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
  font-size: 20px;
}

.mfa-setup .toggle-section {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.mfa-setup .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-right: 10px;
}

.mfa-setup .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.mfa-setup .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 24px;
  transition: 0.4s;
}

.mfa-setup .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.mfa-setup input:checked+.slider {
  background-color: #4caf50;
}

.mfa-setup input:checked+.slider:before {
  transform: translateX(26px);
}

.mfa-setup .mfa-options {
  margin-top: 0px;
}

.mfa-setup .option {
  margin-bottom: 10px;
}

.mfa-setup .setup-btn {
  padding: 10px 20px;
  color: white;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.mfa-setup .setup-btn:hover {
  background-color: #45a049;
}

.mfa-setup .mfa-options p {
  text-align: left !important;
  margin: 0px 0px 12px !important;
  font-size: .85rem;
  color: #555;
}

p.t-show-hide-secret-key {
  font-weight: 600;
  cursor: default;
  top: -12px;
  position: relative;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.mfa-setup .mfa-options>.download-qr {
  display: none;
}

.mfa-setup .mfa-options p span.qr-scan-image {
  text-align: left;
  display: block;
  font-size: .85rem !important;
  margin: 0 0 -5px 0 !important;
  font-weight: 600;
  z-index: 1;
  position: relative;
  color: #f67928;
  text-transform: uppercase;
}

p.t-show-hide-secret-key {
  font-size: .85rem !important;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .02rem;
  color: #f67928 !important;
}

.mfa-setup.mfa-toggled-setup .secret-key-wraps p.t-show-hide-secret-key svg.MuiSvgIcon-root {
  font-size: 1.3rem;
  cursor: pointer;
}

span.secret-key-text {
  font-size: .85rem;
  display: inline-block !important;
  margin: 0 0 0 0px;
  padding: 8px 70px 8px 20px;
  font-weight: 600 !important;
  color: #f67928 !important;
  cursor: default;
  text-transform: none;
  background: #ffe9c8;
  border-radius: 24px;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

button.copy-secret-key svg {
  position: relative;
  width: 18px;
  fill: #f67928;
}

.secret-key-wraps {
  display: flex;
  grid-gap: 0px;
  grid-gap: 0px;
  gap: 0px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 12px 0 12px 0;
  min-height: 35px;
  flex-direction: column;
  position: relative;
  text-align: left;
  padding: 0 0 0 70px;
  border-left: 1px solid #e3e3e3;
  width: 40%;
}

.secret-key-wraps::before {
  position: absolute;
  left: -19px;
  content: "OR";
  color: #f37335;
  background: #fce9c8;
  padding: 0px 0px;
  border: 1px solid #ffdca7;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 30px;
  font-weight: 600;
  vertical-align: middle;
  top: 36%;
  width: 36px;
  height: 36px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.copy-secret-key {
  position: relative;
  right: 43px;
  background: transparent;
  border: 0;
  display: inline-block;
  top: 1px;
  cursor: pointer;
  z-index: 9999;
}

.button-group.mfa-flex-btns {
  width: 100% !important;
}

.mfa-setup.mfa-toggled-setup::-webkit-scrollbar-track,
.mfa-setup.mfa-toggled-setup::-webkit-scrollbar-thumb {
  display: block !important;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code p {
  text-align: left;
  margin: 0 0 8px !important;
}

.mfa-setup.mfa-toggled-setup canvas.canvasQr {
  width: 240px;
  margin: 0 0 -12px 0;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code {
  text-align: center;
  margin: 0px auto 0;
  position: fixed;
  width: 60em;
  left: 50%;
  padding: 25px 20px 15px;
  z-index: 1111;
  border: 0;
  height: auto;
  background: #fff;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.mfa-setup.mfa-toggled-setup .secret-key-wraps p.t-show-hide-secret-key {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0px 0px 0px !important;
  /* cursor: default; */
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code input[type="number"] {
  width: 70%;
  height: 42px;
  padding: 0px 20px;
  border-radius: 40px;
  border: 1px solid #ccc;
  margin: 0 0 15px 0;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code .button-group {
  width: 70%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto 20px;
  grid-gap: 15px;
  gap: 15px;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code .button-group button {
  padding: 10px 25px;
  border-radius: 30px;
  border: 1px solid;
  background: transparent;
  cursor: pointer;
  right: -7px;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code h5 {
  margin-bottom: 12px;
  font-size: 1em;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code .button-group button:first-child {
  background-color: #777777;
  color: #fff;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code .button-group button:last-child {
  background-color: #ff7927;
  color: #fff;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code h6 {
  font-size: .85rem;
  font-weight: 400;
  color: #333;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code h6 button {
  font-size: .85rem;
  font-weight: 600;
  color: #f67928;
  background: transparent;
  border: none;
  cursor: pointer;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code.mfa-enabled-class {
  height: auto;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code h3 {
  text-align: left;
}

.mfa-apps {
  color: #f67928 !important;
  cursor: pointer;
}

button.copy-secret-key.disabled {
  background-color: #cacaca !important;
}

.skip-mfa-note {
  position: absolute;
  background: #FF9800;
  background: #FDC830;
  background: linear-gradient(to right, #F37335, #FDC830);
  display: flex;
  padding: 6px 14px;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 61vw;
  top: 0px;
  left: 0vw;
  border-radius: 10px 10px 0px 0px;
  z-index: 11111;
}

.skip-mfa-note p {
  margin: 0 !important;
  text-align: left !important;
  font-size: 12px;
  color: #fff;
  line-height: 13px;
  /* display: flex
; */
  /* align-items: center; */
  position: relative;
  top: 5px;
}

button.copy-secret-key.skip-mfa-button {
  min-width: 100px;
  font-weight: bold;
  color: #fff;
  right: 0;
  top: 0;
  background: transparent;
  padding: 8px 2px;
  border-radius: 6px;
  border: 1px solid #fff;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code .generatedQr a {
  text-decoration: none;
  cursor: default;
  z-index: 0;
  position: relative;
  pointer-events: none;
}

.mfa-otp-group {
  display: flex;
  width: 100%;
  position: relative;
  grid-gap: 10px;
  gap: 10px;
}

.mfa-otp-group .form-group {
  width: 50%;
  display: flex;
}

.mfa-otp-group .form-group input[type="number"] {
  width: 100% !important;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code .button-group form {
  display: flex;
  justify-content: center;
  grid-gap: .6em;
  gap: .6em;
}

/* .button-group.mfa-flex-btns form {
  display: flex;
  gap: 10px;
} */
.statement-transactins-wrapper .tool-bars {
  top: -104px !important;
}

.statement-transactins-wrapper .table-btn.list-btn.view-details.approveButton {
  top: 59px !important;
  right: 65px !important;
}

.statement-transactins-wrapper .tool-bars button.tooltip-menu.refresh.statement-transaction-refresh-button {
  width: 42px !important;
  right: 10px !important;
  left: auto !important;
}

.statement-transactins-wrapper .transaction-table .das-table span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.Mui-disabled {
  opacity: .5;
}

.statement-transactins-wrapper .transaction-table .das-table span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.Mui-disabled svg path {
  color: #aaa;
}

.api-keys-wrap {
  position: relative;
  width: 100%;
}

.merchant-details-drawer .box-listing.row-between.w-100.live-api {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.merchant-details-drawer .box-listing.row-between.w-100.live-api .row-between {
  width: 100%;
  display: flex;
  position: absolute;
  margin: 0;
  justify-content: flex-end;
  right: 80px;
  top: -45px;
}
.box-listing.row-between.w-100.live-api h4.w-48.font12 {
  margin: 0;
}
.api-keys-wrap h4.transaction-title {
  display: block;
  margin: 0 -22px 0 -21px;
  border-top: 1px solid #ccc;
  padding: 15px 22px;
  text-transform: uppercase;
}

.MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper::-webkit-scrollbar-thumb,
.MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper::-webkit-scrollbar-track {
  display: block !important;
}

.hash-card-wrap {
  position: relative;
  /* margin-top: 2.7em; */
}

.hash-card-wrap .pro-detail-table .das-table {
  height: calc(100vh - 160px);
}

/* .hash-card-wrap .pro-detail-table button.das-add-btn.setup-risk-btn {
  top: -46px !important;
  left: 0;
  right: auto;
} */
.MuiTooltip-arrow {
  color: #e77236 !important;
}

button.edit-hash-card-btn {
  background: transparent;
  border: 0;
  padding: 5px 8px;
  cursor: pointer;
}

.btn-complete-kyc {
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(14deg, #F4AE40, #ce6224);
  border: none;
  padding: 6px 12px;
  font-weight: bold;
}
#register-form .number-input-field.rounded-input-field .ant-input-number-affix-wrapper.ant-input-number-outlined.ant-input-number-has-feedback {
  border-radius: 40px 40px 40px 40px !important;
  border-radius: 6px;
}

/* =============== onfido new full screen poup====================*/
.onfido-new-popup div#onfido-mount {
  width: 35vw;
  height: calc(100vh - 120px);
  margin: 0 auto;
}

.onfido-new-popup {
  position: relative;
  height: calc(100vh - 64px);
  display: flex;
}

.onfido-new-popup .left-onfido-description {
  width: 65vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  text-align: center;
  border-left: 1px solid #e7e7e7;
  position: relative;
}

.onfido-new-popup .left-onfido-description .das-swiper {
  width: 100%;
  height: calc(100vh - 64px) !important;
}

.onfido-new-popup div#onfido-mount div#onfido-sdk {
  max-width: 90%;
  margin: 0 auto;
}

img.onfideoSteps {
  width: 70%;
  display: block;
}

.share-link-div {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  font-size: 1.2em;
}

.share-link-div p strong {
  color: #fb8c33;
}

p.onfido-sharing-link {
  background: #fff0dd;
  color: #fb8c33;
  padding: 5px 20px 5px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.secure-link-wrap {
  position: relative;
}

.secure-link-wrap button {
  position: absolute;
  right: -31px;
  background: #fff0dd;
  border: 1px solid;
  top: -5px;
  width: 40px;
  height: 40px;
  color: #fb8c33;
  border-radius: 24px;
  cursor: pointer;
}

.secure-link-wrap button svg {
  font-size: 1.3rem;
  position: relative;
  top: 1px;
}

.onfido-logo-wraps {
  position: absolute;
  bottom: 15px;
  width: 98%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.onfido-logo-wraps img {
  width: 54px;
}

.successful-onfido-description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 85px);
  padding: 20px 20px 0;
  position: relative;
  grid-gap: 20px;
  gap: 20px;
}

.successful-onfido-description img.verifiedKyc {
  width: 30rem;
}

.successful-onfido-description h2 {
  font-size: 2.5rem;
}

.successful-onfido-description button {
  background: linear-gradient(14deg, rgb(244, 174, 64), rgb(206, 98, 36));
  border: 0;
  padding: 12px 20px;
  border-radius: 20px;
  color: #fff;
  font-size: .85rem;
  letter-spacing: .035rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.successful-onfido-description h5 {
  color: grey;
  font-size: .85rem;
  font-weight: 400;
  letter-spacing: .02rem;
  margin: 0 0 4px 0;
}


.statement-transactins-wrapper button.refresh.statement-transaction-refresh-button {
  width: auto;
  left: auto !important;
  right: 10px;
}

.bulk-action-buttons button {
  color: #e77236;
  border-color: #e77236;
  grid-gap: 2px;
  gap: 2px;
}
.risk-ref-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  position: relative;
}
.risk-ref-amt {
  display: flex;
  border: 1px solid #c3c3c3;
  border-radius: 44px;
  padding: 4px 15px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  justify-content: space-between;
}
.risk-ref-amt.risk-amt-currency {
  justify-content: space-between;
}
/*  ===========  ===============*/
button.transaction-xray-button {
  position: relative;
  top: 30px;
  right: 15px;
  float: right;
  border: 1px solid;
  cursor: pointer;
  color: #e77236;
  margin-left: 10px;
  background: 0;
  padding: 6px 13px;
  border-radius: 6px;
  z-index: 9;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  line-height: normal;
}

button.transaction-xray-button svg {
  margin: 0 6px 0 0;
  font-size: 16px;
  background: #fff;
}

.bulk-action-buttons button.text-action-button:hover {
  border-color: #e77236;
  background: #fff;
}

button.mfa-reset-button {
  cursor: pointer;
  background: transparent;
  border: 0;
  color: #f67928;
  font-weight: 400;
  text-transform: capitalize;
}

.statement-transactins-wrapper span.ant-select-arrow span.anticon.anticon-down.ant-select-suffix svg {
  fill: orangered;
}

.dlfex button.download-limit-ok {
  margin: 15px 0 0 0;
  padding: 10px 20px;
  border-radius: 40px;
}

.dlfex button.download-limit-ok+.download-limit-ok {
  background-color: #333;
}

.MuiDialog-root.yes-whitelist-popup .MuiDialogActions-root.MuiDialogActions-spacing.action-buttons {
  display: none;
}

.MuiDialog-root.no-whitelist-popup .MuiDialogActions-root.MuiDialogActions-spacing.action-buttons {
  display: none;
}

.MuiDrawer-root.MuiDrawer-modal.drawer-wrapper.qr-wrapper-drawer .generatedQr {
  flex-direction: column;
}

.MuiDrawer-root.MuiDrawer-modal.drawer-wrapper.qr-wrapper-drawer .generatedQr button.qr-generate.download-qr {
  width: 100%;
  margin: 0 0 0 0 !important;
}

p.pd-l-r {
  margin: 0;
  padding: 0 40px;
}

.ant-tooltip .ant-tooltip-inner {
  background-color: #f6921e !important;
}

.ant-tooltip-arrow::after {
  background-color: #f6921e !important
    /* Replace with your desired color */
}

.all-onboarding-form .ant-select-selection-overflow {
  padding: 5px 0 5px 10px;
}

.all-onboarding-form span.ant-select-selection-item {
  border-radius: 60px !important;
}
.risk-ref-cont {
  position: relative;
  width: 100%;
}
.risk-ref-amt input {
  padding: 4px 0;
}
.risk-ref-amt input:focus {
  outline: 0;
}
.disabled-prop {
  background: #f6f6f6 !important;
}
.flex-row.align-items-center.product-risk-drawer-wp .ant-select.ant-select-outlined .ant-select-selector {
  border-radius: 100px;
}
.detail-container.partner-details-container .tabs-layout {
  padding: 15px 0 0 0;
}

.detail-container.partner-details-container .tabs-layout h4.transaction-title:first-child {
  padding-top: 5px;
}

.detail-container.merchant-detail-inner .box-listing.row-between.w-100.live-api .row-between {
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}

.detail-container.merchant-detail-inner .box-listing.row-between.w-100.live-api h4.w-48.font12 {
  width: 48.333%;
  word-break: break-all;
}

.comments-wraps {
  display: flex;
  border: none;
  padding: 10px 0px;
  margin: 0 auto;
  height: 45vh;
  border-radius: 10px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}

.comments-wraps::-webkit-scrollbar-track,
.comments-wraps::-webkit-scrollbar-thumb {
  display: block;
}

.comments-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
  border-bottom: 1px solid #ccc;
  margin: 0 0 7px 0;
  padding: 0 20px 5px;
}

.comments-list:last-child {
  border: none;
}

h4.comments-headings {
  position: relative;
  padding: 7px 0 4px 23px;
  text-transform: uppercase;
  letter-spacing: .03rem;
  margin: 0 0 0px 29px;
  background: #f79815;
  display: block;
  width: 111px;
  color: #ffff;
  border-radius: 6px 6px 0px 0px;
}

.comments-list svg {
  display: inline-flex;
  font-size: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.comments-list h5 {
  font-weight: 400;
  font-size: .85rem;
  word-break: break-word;
  white-space: normal;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin: 0 0 4px 0;
}

.comments-list p {
  font-size: .8rem;
  display: flex;
  color: #8c7171;
  justify-content: flex-start;
  grid-gap: 5px;
  gap: 5px;
}

.comments-table-wraps {
  display: flex;
  grid-gap: 6px;
  position: absolute;
  grid-gap: 6px;
  grid-gap: 6px;
  gap: 6px;
  justify-content: center;
  align-items: center;
  top: 14px;
  right: 20px;
  color: #fff;
  cursor: pointer;
}

.comments-table-wraps div {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.comments-popover-wrap .MuiPaper-root.MuiPaper-elevation {
  width: 360px;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code.mfa-enabled-class {
  height: auto;
  position: relative;
  width: auto;
  left: auto;
  padding: 20px;
  top: auto;
  transform: none;
  border: 1px solid #ddd;
}

.mfa-setup.mfa-toggled-setup.verify-mfa-code.no-skip-div {
  padding-top: 25px;
}

.secret-key-qr-wrap {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
}

.secret-key-wraps button.copy-secret-key {
  position: absolute;
  right: 15px;
  top: 6px;
}

.mfa-qr-wrapper {
  width: 23%;
}

.bulk-action-buttons.bulk-action-buttons-left {
  justify-content: flex-start;
}

.pro-detail-table.holiday-table-view .bulk-action-buttons.bulk-action-buttons-left {
  justify-content: flex-end;
}

.pro-detail-table.holiday-table-view .bulk-action-buttons.bulk-action-buttons-left button.export-to-excel.addHoliday {
  right: 130px;
}

h4.mfa-setup-title {
  display: flex;
  width: 100%;
  position: relative;
  font-size: 1.1rem;
  margin: -8px 0px 15px 0px;
  color: #000;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: .04rem;
  font-weight: 600;
  padding: 0 0 10px 0;
}

h4.mfa-setup-title::after {
  background: #e9e9e9;
  position: absolute;
  left: -20px;
  bottom: 0;
  content: "";
  width: calc(100% + 42px);
  height: 1px;
}

.new-login-box .new-log-box .MuiPopper-root.language-popper {
  z-index: 11111;
}

.bulk-action-buttons.bulk-action-buttons-left.bulk-action-buttons-rights button.export-to-excel.addHoliday {
  right: 72px !important;
}

.statement-action-option .MuiButtonBase-root.MuiButton-root.table-btn.list-btn.view-details.Mui-disabled {
  pointer-events: all;
}

.cursor-pointer {
  cursor: pointer;
}
.MuiDrawer-root.MuiDrawer-modal.drawer-wrapper.qr-wrapper-drawer .generatedQr {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
}
.detail-container.adding-pbl-form .add-cart-container.pbl-qr-div.w-30 .generatedQr {
  left: 0;
}
.MuiTextField-root.acquired-form-identifier.acq-code-ident input {
  text-transform: uppercase;
}
/* ========= */
.MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
  top: 48px;
  height: calc(100vh - 0px);
}
.MuiDrawer-root.MuiDrawer-modal.drawer-wrapper.MuiModal-root .MuiBackdrop-root.MuiModal-backdrop {
  top: 48px;
  height: calc(100vh - 48px);
}
.drawer-body {
  padding-bottom: 90px !important;
}
.transaction-popper-drawer {
  padding-bottom: 80px;
}
.MuiPopper-root.language-popper{
z-index: 9999 !important;
}
.ant-popover.ant-popover-placement-bottom {
  z-index: 1301;
}
button.das-add-btn.setup-risk-btn.pbl-add-btn {
  top: 7px;
}
.qr-div-wrap.product-qr-div-wrap{
  margin-bottom: 60px;
}
.formWrap.qr-section.pro-form-qr-sec {
  margin-bottom: 65px;
}
.live-api-key-section-m-details {
  position: relative;
  margin: 20px 0 20px 0;
}
.merchant-details-drawer .live-api-key-section-m-details {
  margin: 0 !important;
}
.icon-color.ml-2.cursor-pointer.encode-decode {
  width: auto;
}
.statement-wrap-left span h4 {
  font-weight: 400;
  margin: 0;
  font-size: .9rem;
}

.download-zip-pdf svg {
  cursor: pointer;
  margin: 0 0 0 5px;
  position: relative;
  padding: 5px 5px;
  width: 20px;
  top: 3px;
}
.download-zip-pdf svg path {
  color: #737373 !important;
}
.download-zip-pdf.active svg path {
  color: #f6921e !important;
}
.bulk-statement-download {
  position: relative;
}
span.badge.bulk-badge {
  left: auto;
  right: -11px;
  padding: 0 0px;
  text-align: center;
}
.bulk-statement-download button.MuiButtonBase-root {
  background: #fff;
  padding: 4px 13px;
  transition: all 0.5s ease 0s;
}
.bulk-statement-download button.MuiButtonBase-root:hover{
  background: #f6921e !important;
  border-color: #FFAF69;
  color: #fff !important;
}
.swapper {
  position: relative;
  border-bottom: 1px solid #dedede;
  padding: 2px 10px 9px;
  margin: 0px 0 6px -4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: -10px;
  grid-gap: 8px;
  gap: 8px;
}
.swapper svg.MuiSvgIcon-root {
  color: #ccc;
  cursor: pointer;
}
.swapper svg.MuiSvgIcon-root.icon-active {
  color: #e77236;
}
.list-item {
  padding: 5px 10px 4px 10px;
  margin: 0 0 10px 0;
  border: 1px solid #dedede;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 1px 0px 5px, rgba(0, 0, 0, 0.24) 0px 0px 1px;
  cursor: grab;
  font-size: .8rem;
  display:flex;
  grid-gap:8px;
  gap:8px;
  align-items:center;
  height:1.5rem
}

.list-item svg {
  width: 10px;
  margin-right: 10px;
  position: relative;
  top: 3px;
}
.new-additional-pref-column.new-order-sorting-list.additional-preference-column .additional-filter-box.additional-preference-box .additionalFilterScroller.filter-scroller {
  max-height: 63vh;
  padding: 0 10px 0 0;
}
.filter-footer.sorting-footer {
  margin: 6px 0 0 -3px !important;
  width: 86%;
}
.pro-detail-table.statement-pro-details button.icon-action-button.add-filt-btn {
  position: absolute;
  left: auto;
  right: 70px;
  top: -45px;
}

.statement-pro-details .right-action-buttons {
  right: 7.7rem;
}
.preference-checkbox{
  width:"28px";
  cursor:"pointer";
  justify-content:"center";
  align-items:"center";
  height:"1.2rem";
  position:'relative';
  top:"-2px"
}

/* .ant-modal-mask{
  z-index: 99998 !important;
}
.ant-modal-wrap {
  z-index: 99999 !important;
} */
.modal-fotter {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}
.signInasMerchant .ant-modal-content {
  padding: 25px 30px;
}
.signInasMerchant .ant-modal-header {
  position: relative;
  margin: -10px 0 15px 0;
}
.ant-modal-footer{
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px !important;
}
button.footer-modal-button {
  padding: 8px 16px;
  border-radius: 24px;
  border: 1px solid;
  cursor: pointer;
  letter-spacing: .03rem;
}
button.footer-modal-button.cancel {
  color: #fff;
  border-color: #969ca3;
}
button.footer-modal-button.submit:disabled{
  background-color: #ccc;
  color: #000;
  border-color: #ccc;
  cursor: not-allowed;
}
button.footer-modal-button.submit {
  background: #e56523;
  color: #fff;
  border-color: #e56523;
}
/* for safari browser*/
@supports (-webkit-hyphens:none)
{
  .filter-scroller.filter-scroller-visible {
    overflow: visible;
  }

}

.SnackbarItem-variantSuccess {
    background-color: #258750 !important;
}
.SnackbarItem-variantError {
    background-color: #e2445c !important;
}
.loader-container {
    position: fixed;
    background: rgba(225, 225, 225, .5);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    z-index: 999999;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader-gif{
    width: 120px;
    height: auto;
  }
  

   
