.loader-container {
    position: fixed;
    background: rgba(225, 225, 225, .5);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    z-index: 999999;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader-gif{
    width: 120px;
    height: auto;
  }
  

   